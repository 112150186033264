/* Vendor files ------------------------------------ */

import aos from './partial/aos';
import change from './partial/header-change-on-scroll';

// Accordion
$('.accordion-trigger').click(function () {
	$(this).toggleClass('active');
	$(this).next().slideToggle(200).parent().toggleClass('is-active');
});

// Placeholder delete on focus
// $(function () {
// 	$('input,textarea')
// 		.focus(function () {
// 			$(this)
// 				.data('placeholder', $(this).attr('placeholder'))
// 				.attr('placeholder', '');
// 		})
// 		.blur(function () {
// 			$(this).attr('placeholder', $(this).data('placeholder'));
// 		});
// });

$('h1, h2, h3, h4, h5, p').each(function () {
	var tekst = $(this).html();
	tekst = tekst.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;'); //jednoznakowe
	tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, '$1$2&nbsp;'); //dwuznakowe
	$(this).html(tekst);
});

var max = 200;
var tot, str;
$('.js-text-ellipsis').each(function () {
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

$('.js-text-ellipsis-140').each(function () {
	var max = 140;
	var tot, str;
	str = String($(this).html());
	tot = str.length;
	str = tot <= max ? str : str.substring(0, max + 1) + '...';
	$(this).html(str);
});

tabControl();

/*
We also apply the switch when a viewport change is detected on the fly
(e.g. when you resize the browser window or flip your device from 
portrait mode to landscape). We set a timer with a small delay to run 
it only once when the resizing ends. It's not perfect, but it's better
than have it running constantly during the action of resizing.
*/
var resizeTimer;
$(window).on('resize', function (e) {
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function () {
		tabControl();
	}, 250);
});

/*
The function below is responsible for switching the tabs when clicked.
It switches both the tabs and the accordion buttons even if 
only the one or the other can be visible on a screen. We prefer
that in order to have a consistent selection in case the viewport
changes (e.g. when you esize the browser window or flip your 
device from portrait mode to landscape).
*/
function tabControl() {
	var tabs = $('.tabbed-content').find('.tabs');
	if (tabs.is(':visible')) {
		tabs.find('a').on('click', function (event) {
			event.preventDefault();
			var target = $(this).attr('href'),
				tabs = $(this).parents('.tabs'),
				buttons = tabs.find('a'),
				item = tabs.parents('.tabbed-content').find('.item');
			buttons.removeClass('active');
			item.removeClass('active');
			$(this).addClass('active');
			$(target).addClass('active');
		});
	} else {
		$('.item').on('click', function () {
			var container = $(this).parents('.tabbed-content'),
				currId = $(this).attr('id'),
				items = container.find('.item');
			container.find('.tabs a').removeClass('active');
			items.removeClass('active');
			$(this).addClass('active');
			container.find('.tabs a[href$="#' + currId + '"]').addClass('active');
		});
	}
}

// menu horizontal scrolling
$('.sticky nav a').on('click', function () {
	$('.sticky nav a').removeClass('selected');
	$(this).addClass('selected');
	// CALL scrollCenter PLUSGIN
	$('.sticky nav').scrollCenter('.selected', 200);
});

jQuery.fn.scrollCenter = function (elem, speed) {
	var active = jQuery(this).find(elem); // find the active element
	var activeWidth = active.width() / 2; // get active width center
	var pos = active.position().left + activeWidth; //get left position of active li + center position
	var elpos = jQuery(this).scrollLeft(); // get current scroll position
	var elW = jQuery(this).width(); //get div width
	//var divwidth = jQuery(elem).width(); //get div width
	pos = pos + elpos - elW / 2 + 32 + 'px'; // for center position if you want adjust then change this

	jQuery(this).animate(
		{
			scrollLeft: pos,
		},
		speed == undefined ? 1000 : speed
	);
	return this;
};

$('sticky nav a.selected').trigger('click');

// Search

$(document).ready(function () {
	$('#dynamic-search').keyup(function () {
		// Search text
		var text = $(this).val().toLowerCase();

		// Hide all content class element
		$('.search-content').hide();

		// Search City
		$('.search-content .search-city').each(function () {
			if (
				$(this)
					.text()
					.toLowerCase()
					.indexOf('' + text + '') != -1
			) {
				$(this).closest('.search-content').show();
			}
		});

		// Search Title
		$('.search-content .search-title').each(function () {
			if (
				$(this)
					.text()
					.toLowerCase()
					.indexOf('' + text + '') != -1
			) {
				$(this).closest('.search-content').show();
			}
		});
	});
});
