/* ----------------------------------------------------
// Header change on scroll
// ------------------------------------------------- */

$(document).ready(function () {
	$(function () {
		$(document).scroll(function () {
			var $nav = $('header');
			var $content = $('main')
			$nav.toggleClass('scroll-change', $(this).scrollTop() > $nav.height());
			$content.toggleClass('scroll-change-js', $(this).scrollTop() > $nav.height());
		});
	});
});
