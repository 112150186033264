/* ----------------------------------------------------
// Aos
// ------------------------------------------------- */


	// Add data-aos="" to element with class .aos
	const addDataAos = () => {
		const elements = document.querySelectorAll('.aos');
		// if data-aos="" exist do nothing
		elements.forEach(item => {
			if (!item.dataset.aos) {
				item.dataset.aos = 'fade-up';
			}
		});
	};
	addDataAos();

	// Aos media query
	var md = window.matchMedia('(min-width: 768px)');
	media(md); // Call listener function at run time
	md.addListener(media); // Attach listener function on state

	function media(md) {
		if (md.matches) {
			// If media query matches
			AOS.init({
				duration: 500,
				startEvent: 'load',
				once: true
			});
		} else {
			AOS.init({
				offset: 150,
				duration: 500,
				startEvent: 'load',
				once: true
			});
		}
	}
